<template>
  <div class="useForm">
    <!-- 使用记录 v-if="!pagination" -->
    <topNav v-if="pagination" :title="title"></topNav>
    <div class="top" v-if="pagination"></div>
    <div class="block-title">
      <strong>{{ $t("使用记录") }}</strong>
    </div>
    <el-table ref="filterTable" :data="useList" style="width: 100%" :height="pagination ? '400' : ''">
      <el-table-column :label="$t('日期')" :width="this.pagination ? '100' : ''">
        <template slot-scope="scope">
          <span class="timestamp" style="white-space: pre-line;">
            {{ scope.row.timestamp }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="num" :label="$t('使用情况')" :width="this.pagination ? '100' : ''">
      </el-table-column>
      <el-table-column prop="desc" :label="$t('描述')" :formatter="formatterPay" :width="this.pagination ? '' : ''">
        <template slot-scope="scope">
          <el-tag :type="scope.row.desc == 2 ? 'warning' : 'success'" disable-transitions>
            <!-- {{ scope.row.desc | descType }} -->
            <!-- {{ $tc('descType', scope.row.desc ) }} -->
            {{ $t("descType" + scope.row.desc) }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="action"
        :label="this.pagination ? '流水' : '流水类型'"
        :width="this.pagination ? '80' : ''"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.action == 0 ? 'warning' : 'success'"
            disable-transitions
          >
            {{ scope.row.action | actionType }}
          </el-tag>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="block">
      <el-pagination :small="pagination" @size-change="handleSizeChangePay" @current-change="handleCurrentChangePay"
        :current-page="currentPageUse" :page-sizes="[10, 15, 20, 100]" :page-size="usePageSize" :layout="layout"
        :total="useTotal">
      </el-pagination>
    </div>
    <div class="top" v-if="pagination"></div>
    <div class="top"></div>
  </div>
  <!-- <div v-else>
    <topNav :title="title"></topNav>
        <div class="useMobile">
      <div class="useList">
        <div class="use" v-for="(i, index) in useList" :key="index">
          <div :class="'useWay' + i.desc">
            {{ $tc('descType', i.desc ) }}
          </div>
          <div class="nums">
            <span> {{$t('日期')}}：{{ i.timestamp }} </span>
            <span> {{$t('使用情况')}}：{{ i.num }} </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { usageTracker } from "../../../config/api";
import topNav from "../../../components/topNav/index.vue";
export default {
  name: "useForm",
  components: { topNav },
  data() {
    return {
      /**我的使用记录 */
      useList: [
        {
          timestamp: "2023-06-08 09:24:12",
          num: 20,
          desc: 2
        },
        {
          timestamp: "2023-06-26 10:16:50",
          num: 10,
          desc: 1
        }
      ],
      currentPageUse: 1,
      usePageSize: 10,
      useTotal: 5,
      pagination: false, //是否开启小型模式
      layout: "total, sizes, prev, pager, next, jumper",
      /**移动端数据 */
      title: {
        left: true,
        name: this.$t("使用记录"),
        right: false
      }
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // 获取使用记录
    this.getUsageTracker();
  },
  filters: {
    descType(key) {
      if (key == 1) return "对话消耗";
      if (key == 2) return "到期失效";
    }
  },
  methods: {
    getUsageTracker() {
      this.useList = [];
      usageTracker(this.currentPageUse, this.usePageSize).then(res => {
        this.useList = res.data.records;
        this.dailyBalance = res.data.balance;
        this.useTotal = res.data.size;
      });
    },
    handleResize() {
      this.pagination = window.innerWidth < 768;
      if (this.pagination) {
      }
      this.layout = "prev, pager, next";
    },
    formatterPay(row, column) {
      return row.desc;
    },
    // 回复记录
    handleSizeChangePay(val) {
      this.usePageSize = val;
      this.getUsageTracker();
    },
    handleCurrentChangePay(val) {
      this.currentPageUse = val;
      this.getUsageTracker();
    }
  }
};
</script>
<style lang="less" scoped>
.useForm {
  overflow: hidden;
  padding: 0 20px 20px 20px;

  .top {
    margin-top: 20px;
    height: 8rem;
  }

  .block-title {
    margin-top: 20px;
    background-color: #3e4a59;
    padding: 15px;

    >strong {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }
  .block {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less" scoped>
/* 修改表格样式 */
.el-table {
  /deep/ .cell {
    padding: 0;
    height: 50px !important;
    line-height: 1;
    display: flex !important;
    align-items: center !important;
  }
}

.el-table /deep/ .el-table__cell {
  padding: 0;
}

.el-table {
  margin-bottom: 25px;
}

.el-table /deep/ .el-table__row .el-table__cell .cell>.timestamp {
  white-space: inherit !important;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  
  .block-title {
    margin-top: 0px !important;
  }
  .el-table /deep/ .el-table__header .cell {
    height: 6rem;
    line-height: 1;
    display: flex;
    align-items: center;
  }

  .el-table /deep/ .el-table__body .cell {
    height: 6rem;
    display: flex;
    align-items: center;
  }

  .el-table {
    margin-bottom: 25px;
  }
}

.useMobile {
  .useList {
    .use {
      border: 1px solid #ddd;
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      margin: 1rem;
      padding: 1rem;
      display: flex;
      align-items: center;

      .useWay1,
      .useWay2,
      .useWay3 {
        width: 6rem;
        height: 6rem;
        line-height: 1;
        font-size: 2rem;
        text-align: center;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .useWay1 {
        border: 2px solid #e1f3d8;
        border-radius: 5px;
        background-color: #f0f9eb;
        color: #67c23a;
      }

      .useWay2 {
        border: 2px solid #faecd8;
        border-radius: 5px;
        background-color: #fdf6ec;
        color: #e6a23c;
      }

      .useWay3 {
        border: 2px solid #e2f0ff;
        border-radius: 5px;
        background-color: #ecf5ff;
        color: #46a1ff;
      }

      .nums {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1rem;
        color: #666;
      }
    }
  }
}

.el-table /deep/ .el-table__row .el-table__cell {
  padding: 0 12px;

  .cell>.timestamp {
    white-space: inherit !important;
    line-height: 1;
  }
}
</style>
