<template>
  <!-- 返回组件 -->
  <div class="topNav">
    <div class="left" @click="$router.go(-1)">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="title">{{ title.name }}</div>
    <div class="right"></div>
  </div>
</template>
<script>
export default {
  name: "topNav",
  props: {
    title: {},
    typeof: Object
  }
};
</script>
<style scoped lang="less">
.topNav {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  color: #333;
  font-weight: 700;
  font-size: 2.2rem;
  align-items: center;
  position: relative;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 7rem;
  line-height: 7rem;

  .left {
    height: 4rem;
    line-height: 4rem;

    i {
      font-size: 1.8rem;
      color: #333;
      font-weight: 700;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
